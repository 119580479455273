import React from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Mock data for a single client
const clientData = {
  name: "Sarah Johnson",
  age: 35,
  height: 165, // cm
  weight: 60, // kg
  bmi: 22.0,
  bloodPressure: { systolic: 120, diastolic: 80 },
  cholesterol: { total: 190, hdl: 60, ldl: 110 },
  glucoseLevels: [
    { date: '2023-01', value: 95 },
    { date: '2023-02', value: 98 },
    { date: '2023-03', value: 92 },
    { date: '2023-04', value: 97 },
    { date: '2023-05', value: 94 },
    { date: '2023-06', value: 96 },
  ],
  menstrualCycle: [
    { date: '2023-01', length: 28 },
    { date: '2023-02', length: 30 },
    { date: '2023-03', length: 29 },
    { date: '2023-04', length: 28 },
    { date: '2023-05', length: 31 },
    { date: '2023-06', length: 29 },
  ],
  lifestyle: {
    exerciseMinutesPerWeek: 150,
    sleepHoursPerNight: 7,
    stressLevel: 6, // Scale of 1-10
  },
  nutrition: {
    caloriesPerDay: 2000,
    proteinGrams: 75,
    carbGrams: 250,
    fatGrams: 65,
  },
  boneHealth: {
    calciumIntake: 1000, // mg per day
    vitaminDLevel: 30, // ng/mL
    boneDesityTScore: -0.5,
  },
  futurePredictions: {
    bmiTrend: [
      { year: 2024, value: 22.2 },
      { year: 2025, value: 22.5 },
      { year: 2026, value: 22.8 },
      { year: 2027, value: 23.1 },
      { year: 2028, value: 23.4 },
    ],
    heartDiseaseRisk: 0.15, // 15% risk in the next 10 years
    diabetesRisk: 0.08, // 8% risk in the next 10 years
    osteoporosisRisk: 0.12, // 12% risk by age 60
  },
};

const MetricCard = ({ title, value, unit }) => (
  <div className="w-full md:w-1/3 p-4 bg-white shadow rounded">
    <h3 className="text-lg font-bold">{title}</h3>
    <p className="text-3xl font-semibold">
      {value} <span className="text-sm text-gray-500">{unit}</span>
    </p>
  </div>
);

const OverviewScreen = () => (
  <div>
    <div className="flex flex-wrap gap-4 mb-8">
      <MetricCard title="BMI" value={clientData.bmi.toFixed(1)} unit="kg/m²" />
      <MetricCard title="Blood Pressure" value={`${clientData.bloodPressure.systolic}/${clientData.bloodPressure.diastolic}`} unit="mmHg" />
      <MetricCard title="Total Cholesterol" value={clientData.cholesterol.total} unit="mg/dL" />
    </div>
    
    <div className="w-full p-4 mb-4 bg-white shadow rounded">
      <h2 className="text-xl font-bold mb-4">Glucose Levels (Last 6 Months)</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={clientData.glucoseLevels}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </div>
);

const CardioScreen = () => (
  <div className="w-full p-4 bg-white shadow rounded">
    <h2 className="text-xl font-bold mb-4">Cardiovascular Health</h2>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Blood Pressure: {clientData.bloodPressure.systolic}/{clientData.bloodPressure.diastolic} mmHg</h3>
      <p>Category: {clientData.bloodPressure.systolic < 120 && clientData.bloodPressure.diastolic < 80 ? 'Normal' : 'Elevated'}</p>
    </div>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Cholesterol Profile</h3>
      <p>Total Cholesterol: {clientData.cholesterol.total} mg/dL</p>
      <p>HDL (Good) Cholesterol: {clientData.cholesterol.hdl} mg/dL</p>
      <p>LDL (Bad) Cholesterol: {clientData.cholesterol.ldl} mg/dL</p>
    </div>
  </div>
);

const ReproductiveScreen = () => (
  <div className="w-full p-4 bg-white shadow rounded">
    <h2 className="text-xl font-bold mb-4">Menstrual Cycle Length (Last 6 Months)</h2>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={clientData.menstrualCycle}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="length" fill="#82ca9d" name="Cycle Length (days)" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

const LifestyleScreen = () => (
  <div className="w-full p-4 bg-white shadow rounded">
    <h2 className="text-xl font-bold mb-4">Lifestyle Overview</h2>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Exercise: {clientData.lifestyle.exerciseMinutesPerWeek} minutes/week</h3>
      <p>Recommendation: 150 minutes of moderate-intensity aerobic activity per week</p>
    </div>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Sleep: {clientData.lifestyle.sleepHoursPerNight} hours/night</h3>
      <p>Recommendation: 7-9 hours per night for adults</p>
    </div>
    <div>
      <h3 className="text-lg font-semibold">Stress Level: {clientData.lifestyle.stressLevel}/10</h3>
      <p>Recommendation: Practice stress-management techniques like meditation or yoga</p>
    </div>
  </div>
);

const NutritionScreen = () => {
  const nutritionData = [
    { name: 'Protein', value: clientData.nutrition.proteinGrams },
    { name: 'Carbs', value: clientData.nutrition.carbGrams },
    { name: 'Fat', value: clientData.nutrition.fatGrams },
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <div className="w-full p-4 bg-white shadow rounded">
      <h2 className="text-xl font-bold mb-4">Nutrition Overview</h2>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Daily Calorie Intake: {clientData.nutrition.caloriesPerDay} calories</h3>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={nutritionData}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {nutritionData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const BoneHealthScreen = () => (
  <div className="w-full p-4 bg-white shadow rounded">
    <h2 className="text-xl font-bold mb-4">Bone Health</h2>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Calcium Intake: {clientData.boneHealth.calciumIntake} mg/day</h3>
      <p>Recommendation: 1000-1200 mg/day for adult women</p>
    </div>
    <div className="mb-4">
      <h3 className="text-lg font-semibold">Vitamin D Level: {clientData.boneHealth.vitaminDLevel} ng/mL</h3>
      <p>Status: {clientData.boneHealth.vitaminDLevel >= 30 ? 'Sufficient' : 'Insufficient'}</p>
    </div>
    <div>
      <h3 className="text-lg font-semibold">Bone Density T-Score: {clientData.boneHealth.boneDesityTScore}</h3>
      <p>Interpretation: {
        clientData.boneHealth.boneDesityTScore > -1 ? 'Normal' :
        clientData.boneHealth.boneDesityTScore > -2.5 ? 'Osteopenia' : 'Osteoporosis'
      }</p>
    </div>
  </div>
);

const PredictionsScreen = () => (
  <div>
    <div className="w-full p-4 mb-4 bg-white shadow rounded">
      <h2 className="text-xl font-bold mb-4">BMI Trend Prediction</h2>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={clientData.futurePredictions.bmiTrend}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis domain={[20, 25]} />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
    <div className="w-full p-4 bg-white shadow rounded">
      <h2 className="text-xl font-bold mb-4">Health Risk Predictions</h2>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Heart Disease Risk (10-year): {(clientData.futurePredictions.heartDiseaseRisk * 100).toFixed(1)}%</h3>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">Diabetes Risk (10-year): {(clientData.futurePredictions.diabetesRisk * 100).toFixed(1)}%</h3>
      </div>
      <div>
        <h3 className="text-lg font-semibold">Osteoporosis Risk (by age 60): {(clientData.futurePredictions.osteoporosisRisk * 100).toFixed(1)}%</h3>
      </div>
    </div>
  </div>
);

const AthenaInsightsDashboard = () => {
  const [activeTab, setActiveTab] = React.useState('overview');

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <h1 className="text-3xl font-bold mb-2">Athena Insights: Comprehensive Health Dashboard</h1>
      <div className="mb-6">
        <h2 className="text-2xl">Client: {clientData.name}</h2>
        <p className="text-lg">Age: {clientData.age} | Height: {clientData.height} cm | Weight: {clientData.weight} kg</p>
      </div>
      
      <div className="mb-4 flex flex-wrap">
        {['overview', 'cardio', 'reproductive', 'lifestyle', 'nutrition', 'bone', 'predictions'].map((tab) => (
          <button
            key={tab}
            className={`px-4 py-2 mr-2 mb-2 rounded ${activeTab === tab ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.charAt(0).toUpperCase() + tab.slice(1)}
          </button>
        ))}
      </div>
      
      {activeTab === 'overview' && <OverviewScreen />}
      {activeTab === 'cardio' && <CardioScreen />}
      {activeTab === 'reproductive' && <ReproductiveScreen />}
      {activeTab === 'lifestyle' && <LifestyleScreen />}
      {activeTab === 'nutrition' && <NutritionScreen />}
      {activeTab === 'bone' && <BoneHealthScreen />}
      {activeTab === 'predictions' && <PredictionsScreen />}
    </div>
  );
};

export default AthenaInsightsDashboard;